<template>
    <div class="broad">
        <h1>This is broad</h1>
        <audio id="remote-audio" autoplay></audio>
        <div class="button-area">
            <button v-on:click="startRecord">Start</button>
            <button v-on:click="stopRecord">Stop</button>
            <button v-on:click="playRecord">Play</button>
        </div>
    </div>
</template>

<script>
import RecordRTC from 'recordrtc';

export default {
    name: 'Broad',
    components: {
    },
    data: function() {
        return {
            recordAudio: null,
            remoteAudioSrc: null,
            fileNo: 0,
        }
    },
    mounted: function() {
        this.player = document.getElementById("remote-audio");
        this.$socket.on('audioStreamBroadcast', (data) => {
            console.log(data);
            // this.remoteAudioSrc = data;
            let blob = new Blob([data]);
            // this.remoteAudioSrc = window.URL.createObjectURL(blob);
            this.player.src = window.URL.createObjectURL(blob);
            console.log(this.player.src);
        });
    },
    methods: {
        playRecord: function() {
            const playPromise = this.player.play();
            if(playPromise !== undefined) {
                console.log("Sound playing!");
            } else {
                console.log("Play failed")
            }
        },
        startRecord2: function() {
            navigator.mediaDevices.getUserMedia({
                audio: true
            }).then((stream) => {
                let mediaRecorder = new MediaRecorder(stream)
                mediaRecorder.onstart = () => {
                    mediaRecorder.chunks = [];
                }
                mediaRecorder.ondataavailable = (e) => {
                    console.log(e.data);
                    mediaRecorder.chunks.push(e.data);
                    const blob = new Blob(mediaRecorder.chunks, {type: 'audio/wav'});
                    // console.log(blob);
                    this.$socket.emit('audioStream', blob);
                }
                mediaRecorder.onstop = () => {
                    // const blob = new Blob(mediaRecorder.chunks, {type: 'audio/wav'});
                    // this.$socket.emit('audioStream', blob);
                }

                mediaRecorder.start(100);

                setTimeout(() => {
                    mediaRecorder.stop();
                }, 1000);
            })
        },
        startRecord: function() {
            navigator.mediaDevices.getUserMedia({
                audio: true
            }).then((stream) => {
                this.recordAudio = new RecordRTC(stream, {
                    type: 'audio',

                    mimeType: 'audio/wav',
                    sampleRate: 44100,
                    // used by StereoAudioRecorder
                    // the range 22050 to 96000.
                    // let us force 16khz recording:
                    desiredSampRate: 16000,

                    // MediaStreamRecorder, StereoAudioRecorder, WebAssemblyRecorder
                    // CanvasRecorder, GifRecorder, WhammyRecorder
                    recorderType: RecordRTC.StereoAudioRecorder,
                    // Dialogflow / STT requires mono audio
                    numberOfAudioChannels: 1,
                    timeSlice: 400,
                    ondataavailable: (blob) => {
                        console.log(blob);
                        const stream = this.$sstream.createStream();
                        this.$sstream(this.$socket).emit('audioStream', stream, {
                            name: 'audioStream_' + (this.fileNo++) + '.wav',
                            // name: 'audioStream.wav',
                            size: blob.size
                        });
                        this.$sstream.createBlobReadStream(blob).pipe(stream);

                        // this.$socket.emit('audioStream2', blob);
                        // this.recordAudio.stopRecording();
                    }
                });

                this.recordAudio.startRecording();
            }, function(error) {
                console.error(JSON.stringify(error));
            });
        },
        stopRecord: function() {
            this.recordAudio.stopRecording(() => {
                this.$socket.emit('audioStreamStop');
                // this.recordAudio.getDataURL((audioDataURL) => {
                //     const files = {
                //         audio: {
                //             type: this.recordAudio.getBlob().type || 'audio/wav',
                //             dataURL: audioDataURL
                //         }
                //     };
                //     console.log(audioDataURL);
                //
                //     this.$socket.emit('audioStream', audioDataURL);
                // })
            });
        }
    }

}
</script>
